<template>
    <div class="py-16 mx-auto" :style="{'text-align': 'center', 'width': divWidth + 'px'}" v-resize="onResize">
        <label class="yellow--text text--darken-3 font-weight-bold d-block mb-4">
            ข่าวประชาสัมพันธ์
        </label>

        <!-- not show on mobile -->
        <v-carousel class="hidden-md-and-down" hide-delimiter-background height="425">
            <template v-slot:prev="{ on, attrs }">
                <v-icon size="30" color="yellow darken-3" v-bind="attrs" v-on="on">
                    mdi-chevron-left-circle-outline
                </v-icon>
            </template>
            <template v-slot:next="{ on, attrs }">
                <v-icon size="30" color="yellow darken-3" v-bind="attrs" v-on="on">
                    mdi-chevron-right-circle-outline
                </v-icon>
            </template>
            <v-carousel-item v-for="i in numberPage" :key="i" :style="{ width: 725 + 'px' }" class="mx-auto">
                <v-layout row>
                    <v-flex sm4 v-for="j in 3" :key="j" pl-3 pr-2>
                        <div v-if="getIndex(i, j) < announcements.length">
                            <v-img class="mb-3" height="200"
                                :src="announcements[getIndex(i, j)].image"></v-img>
                            <label class="yellow--text text--darken-3 font-weight-bold my-2 text-left line-text" style="height: 45px;">{{
                                announcements[getIndex(i, j)].topic }}</label>
                            <!-- <div class="" style="text-align: left;">
                                <label class="white--text" style="text-align: left;">
                                    {{ convertShortContent(announcements[getIndex(i, j)].content) }}
                                    <router-link class="d-block yellow--text text--darken-3"
                                        :to="{ name: 'AnnouncementDetails', params: { id: announcements[getIndex(i, j)].id } }">
                                        อ่านเพิ่มเติม >
                                    </router-link>
                                </label>
                            </div> -->
                            <div class="d-block" style="height: 75px; text-align: left;">
                                <label class="white--text line-text-3">
                                    {{ announcements[getIndex(i, j)].content }}
                                </label>

                            </div>
                            <div class="d-block" style="height: 15px; text-align: left;">
                                <router-link class="d-block yellow--text text--darken-3"
                                    :to="{ name: 'AnnouncementDetails', params: { id: announcements[getIndex(i, j)].id } }">
                                    อ่านเพิ่มเติม >
                                </router-link>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-carousel-item>
        </v-carousel>

        <v-container class="hidden-lg-and-up">
            <v-row v-for="a of announcements" :key="a.id" class="mb-2" no-gutters>
                <v-col cols="6">
                    <v-img class="ma-2" max-width="100%" max-height="120"
                        :src="a.image"></v-img>
                </v-col>
                <v-col cols="6" style="text-align: left;">
                    <div class="ml-5">
                        <label class="line-text font-weight-bold yellow--text text--darken-3">{{ a.topic }}</label>
                        <label class="white--text line-text">{{ a.content }}</label>
                        <router-link class="d-block yellow--text text--darken-3"
                            :to="{ name: 'AnnouncementDetails', params: { id: a.id } }">
                            อ่านเพิ่มเติม >
                        </router-link>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-btn @click="$router.push({name: 'AnnouncementHome'})" outlined rounded color="yellow darken-3"
            class="mx-auto px-8">ดูเพิ่มเติม</v-btn>
        <!-- Util conponents -->
        <dialog-error ref="dialogError" :topic="'Error'" :text="errorMessage" />
    </div>
</template>

<script>
import axios from 'axios';
import DialogError from '../components/DialogError.vue';

export default {
    data: () => ({
        announcements: [ // limit 30
            { id: 1, topic: 'หัวข้อประชาสัมพันธ์1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod', image: '/images/main_6.png' },
            { id: 2, topic: 'หัวข้อประชาสัมพันธ์2', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod', image: '/images/main_7.png' },
            { id: 3, topic: 'หัวข้อประชาสัมพันธ์3', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod', image: '/images/main_8.png' },
            { id: 4, topic: 'หัวข้อประชาสัมพันธ์1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod', image: '/images/main_6.png' },
            { id: 5, topic: 'หัวข้อประชาสัมพันธ์2', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod', image: '/images/main_7.png' }
        ],
        windowSize: {
            x: 0,
            y: 0
        },
        errorMessage: '',
        divWidth: 850,
        newsWidth: 725
    }),
    methods: {
        getIndex(i, j){
            return ((i - 1) * 3 + j) - 1;
        },
        convertShortContent(text){
            return text && text.length >= 71 ? text.toString().substring(0, 71) + ' ...' : text;
        },
        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
            if(this.windowSize.x > 850){
                this.divWidth = 850;
            }else{
                this.divWidth = this.windowSize.x - 40;
            }
        }
    },
    computed: {
        numberPage(){
            return Math.ceil(this.announcements.length / 3);
        }
    },
    async mounted(){
        try{
            const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents?limit=30&isPublish=true');
            this.announcements = response.data;
        }catch(error){
            this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
            this.$refs.dialogError.show();
        }
    },
    components: {
        DialogError
    }
};
</script>

<style scoped>
    .line-text{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
    .line-text-3{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3; 
        -webkit-box-orient: vertical;
    }
</style>