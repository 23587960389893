<template>
  <div class="mt-n6" v-resize="onResize">
    <v-carousel
      cycle
      height="400"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
      >
        <v-sheet
          :color="colors[i]"
          height="100%"
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-img
              src="/images/main_slide1.jpg"
              width="100%"
              height="100%"
            >

            </v-img>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <div class="div-form">
      <form @submit.prevent="$router.push({ name: 'Status', query: { code : textSearch }})">
        <v-text-field 
          v-model="textSearch"
          prepend-inner-icon="mdi-magnify"
          placeholder="กรอกเลข Order Number"
          solo>
          <template v-slot:append>
            <v-btn class="pa-6 mr-n3" 
              @click="$router.push({ name: 'Status', query: { code : textSearch }})"
              depressed color="yellow darken-3">
              เช็คสถานะสินค้า
            </v-btn>
          </template>
        </v-text-field>
      </form>
    </div>
    <div class="mt-10 mx-auto card-movevai-1 py-5">
        <p class="yellow--text text--darken-3 font-weight-bold">"มูฟไว มั่นใจ ประกันภัย 100%</p>
        <label class="yellow--text text--darken-3 font-weight-bold">เราคือบริษัทขนส่งสินค้ามีค่า รับประกันสินค้าเต็มจำนวน</label>
    </div>
    <div class="div-step mt-16 mx-auto" style="text-align: center;">
      <p class="mb-1 font-weight-bold yellow--text text--darken-3">ส่งสิ่งของที่คุณต้องการกับทางมูฟไว ง่ายๆ</p>
      <label class="font-weight-bold white--text text-body-2">เพียง 4 ขั้นตอนดังนี้</label>
      <v-row class="mt-3 mx-0">
        <v-col cols="12" md="3">
          <div class="rounded-circle d-block mx-auto mb-2" style="width: 100px; height: 100px; background-color: #CB9B3E;">
            <div style="height: 100%; padding-top: 25px;"> 
              <v-img
                class="mx-auto"
                src="/images/main_icon1.svg"
                width="65"
              ></v-img>
            </div>
          </div>
          <label class="white--text">1. สมัครสมาชิก</label>
        </v-col>
        <v-col cols="12" md="3">
          <div class="rounded-circle d-block mx-auto mb-2" style="width: 100px; height: 100px; background-color: #CB9B3E;">
            <div style="height: 100%; padding-top: 25px;"> 
              <v-img
                class="mx-auto"
                src="/images/main_icon2.svg"
                width="65"
              ></v-img>
            </div>
          </div>
          <label class="white--text">2. เลือกสินค้าและบริการ</label>
        </v-col>
        <v-col cols="12" md="3">
          <div class="rounded-circle d-block mx-auto mb-2" style="width: 100px; height: 100px; background-color: #CB9B3E;">
            <div style="height: 100%; padding-top: 20px;"> 
              <v-img
                class="mx-auto"
                src="/images/main_icon3.png"
                width="60"
                contain
              ></v-img>
            </div>
          </div>
          <label class="white--text">3. ชำระเงิน</label>
        </v-col>
        <v-col cols="12" md="3">
          <div class="rounded-circle d-block mx-auto mb-2" style="width: 100px; height: 100px; background-color: #CB9B3E;">
            <div style="height: 100%; padding-top: 20px;"> 
              <v-img
                class="mx-auto"
                src="/images/main_icon4.svg"
                width="60"
              ></v-img>
            </div>
          </div>
          <label class="white--text">4. รอรับสินค้า</label>
        </v-col>
      </v-row>
    </div>

    <!-- start youtube -->
    <div v-if="index.section1 && index.section1.youtube" class="pt-16 mx-auto div-why-move-vai" style="text-align: center">
      <youtube :video-id="getIdFromURL(index.section1.youtube)" :player-width="playerWidth"></youtube>
    </div>

    <!-- Start why move-vai -->
    <div class="py-16 mx-auto div-why-move-vai" style="text-align: center">
      <label class="yellow--text text--darken-3 font-weight-bold">
        {{ index.section1.topic }}
      </label>
      <v-row class="mx-0 mt-3">
        <v-col cols="12" md="6">
          <div class="d-flex yellow-block pa-3 mb-3">
              <div class="ma-auto"> {{ index.section1.description1 }} </div>
          </div>
          <div class="image-block">
            <v-img
              height="320"
              :src="index.section1.image1"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="image-block mb-3">
            <v-img
              height="320"
              :src="index.section1.image2"
            ></v-img>
          </div>
          <div class="d-flex yellow-block pa-3">
            <div class="ma-auto"> {{ index.section1.description2 }} </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- End why? move-vai -->
    <!-- Start my services -->
    <my-services class="div-my-service" :serviceInfo="index.section2"></my-services>
    <!-- End My Service -->
    <!-- Start Announcement -->
    <my-announcement class="div-my-announcement"></my-announcement>
    <!-- End Announcement -->
    <dialog-error
        ref="dialogError"
        :topic="'Error'"
        :text="errorMessage"
    />
  </div>
</template>

<script>
import MyServices from '../components/MyServices.vue';
import MyAnnouncement from '../components/MyAnnouncement.vue';
import axios from 'axios';
import DialogError from '../components/DialogError.vue';
import { getIdFromURL } from 'vue-youtube-embed';

  export default {
    name: 'Home',
    data: () => ({
      textSearch: '',
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4'
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth'
      ],
      errorMessage: '',
      index: {
        section1: {},
        section2: {}
      },
      windowSize: {
        x: 0,
        y: 0
      },
      playerWidth: 700,
      getIdFromURL
      }),
    async mounted(){
      try{
        const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents/customer-index');
        this.index = response.data;
      }catch(error){
        this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
        this.$refs.dialogError.show();
      }
    },
    methods: {
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        if(this.windowSize.x > 700){
          this.playerWidth = 700;
        }else{
          this.playerWidth = this.windowSize.x - 40;
        }
      }
    },
    components: {
      MyServices,
      MyAnnouncement,
      DialogError
    }
  };
</script>

<style>
  .v-carousel__controls{
    padding-bottom: 50px;
  }

  .div-form{
    margin-top: -35px;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    width: 40%;
    z-index: 2;
    position: relative;
  }

  .card-movevai-1{
    width: 50%;
    border: thin solid #C49C4F;
    background: transparent;
    text-align: center;
    border-radius: 8px;
  }
  
  .div-step{
    width: 750px;
  }

  .yellow-block{
    background-color: #C49C4F;
    height: 120px;
  }

  .image-block{
    height: 320px;
  }

  .div-why-move-vai, .div-my-service{
    width: 750px;
  }

  .div-my-announcement{
    width: 825px;
    padding-bottom: 150px;
  }

  .col-service{
    height: 300px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  hr.rounded {
  border-top: 1px solid #C49C4F;
  border-radius: 5px;
  }

  @media (max-width: 600px){
  .div-form{
    width: 90%;
  }
  .card-movevai-1{
    width: 100%;
    border: none;
  }
  .div-step{
    width: 100%;
  }
  .div-why-move-vai, .div-my-service, .div-my-announcement{
    width: 100%;
  }
  
  }

</style>
