<template>
    <div class="py-16 mx-auto" style="text-align: center">
        <label class="yellow--text text--darken-3 font-weight-bold">
            {{ serviceInfo.topic || 'บริการทั้งหมดของเรา' }}
        </label>
        <v-row class="mx-0 mt-3">
            <v-col class="col-service" cols="12" md="6">
                <v-img
                    height="280"
                    :src="serviceInfo.channel1 ? serviceInfo.channel1.image : ''"
                ></v-img>
            </v-col>
            <v-col class="col-service" cols="12" md="6">
                <div style="width: 100%">
                <label class="d-block font-weight-bold yellow--text text--darken-3">MOVE SAME DAY</label>
                <hr class="my-2 rounded"/>
                <label class="white--text d-block mb-8">
                {{ serviceInfo.channel1 ? serviceInfo.channel1.content : '' }}
                </label>
                <v-btn outlined rounded color="yellow darken-3" @click="$router.push({ name: 'ServicesDetails', params: {name: 'move-sameday'}})">
                ดูรายละเอียดเพิ่มเติม
                </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row class="mx-0 mt-3">
            <v-col class="col-service" cols="12" md="6">
                <div style="width: 100%">
                    <label class="d-block font-weight-bold yellow--text text--darken-3">MOVE EXPRESS</label>
                    <hr class="my-2 rounded"/>
                    <label class="white--text d-block mb-8">
                    {{ serviceInfo.channel2 ? serviceInfo.channel2.content : '' }}
                    </label>
                    <v-btn outlined rounded color="yellow darken-3" @click="$router.push({ name: 'ServicesDetails', params: {name: 'move-express'}})">
                    ดูรายละเอียดเพิ่มเติม
                    </v-btn>
                </div>
            </v-col>
            <v-col class="col-service" cols="12" md="6">
                <v-img
                    height="280"
                    :src="serviceInfo.channel2 ? serviceInfo.channel2.image : ''"
                ></v-img>
            </v-col>
        </v-row>
        <v-row class="mx-0 mt-3">
            <v-col class="col-service" cols="12" md="6">
                <v-img
                    height="280"
                    :src="serviceInfo.channel3 ? serviceInfo.channel3.image : ''"
                ></v-img>
            </v-col>
            <v-col class="col-service" cols="12" md="6">
                <div style="width: 100%">
                    <label class="d-block font-weight-bold yellow--text text--darken-3">MOVE TRANSIT</label>
                    <hr class="my-2 rounded"/>
                    <label class="white--text d-block mb-8">
                        {{ serviceInfo.channel3 ? serviceInfo.channel3.content : '' }}
                    </label>
                    <v-btn outlined rounded color="yellow darken-3" @click="$router.push({ name: 'ServicesDetails', params: {name: 'move-transit'}})">
                        ดูรายละเอียดเพิ่มเติม
                    </v-btn>
                </div>
            </v-col>
      </v-row>
    </div>
</template>

<script>
export default {
    props: {
        serviceInfo: {
            type: Object,
            default() {
                return {
                    topic: '',
                    channel1: {},
                    channel2: {},
                    channel3: {}
                };
            }
        }
    },
    data: () => ({
        
    })
};
</script>

<style scoped>
    .div-why-move-vai, .div-my-service{
        width: 750px;
    }
    @media (max-width: 600px){
        .div-why-move-vai, .div-my-service, .div-my-announcement{
            width: 100%;
        }
    }
</style>